<template>
  <div class="rating-list-content">
    <div class="rating-list-overflow">
      <div class="rating-header">
        <span class="start rating-span">NADA PROBABLE</span>
        <span class="end rating-span">EXTREMADAMENTE PROBABLE</span>
      </div>
      <ul class="rating-list">
        <div class="items">
          <li
            :style="'width: calc(100% / ' + choices.length + ')'"
            v-for="(choice, indexCh) in choices"
            :key="indexCh"
          >
            <RatingBtn
              :last="indexCh == choices.length - 1"
              v-model="val"
              :number="parseInt(choice)"
              :update="update"
            />
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingList",
  components: {
    RatingBtn: () => import("./ratingBtn.vue"),
  },
  props: {
    value: {
      default: 0,
    },
    choices: {
      Type: Array,
      required: true,
    },
    update: {
      type: Function,
    },
  },
  data() {
    return {
      val: null,
    };
  },
  beforeMount() {
    const valEntry = parseInt(this.value);
    if (!isNaN(valEntry)) {
      this.val = valEntry;
    }
  },
  mounted() {
    console.log("MOUNTED RATING LIST");
  },
  watch: {
    val(newVal) {
      this.$emit("change", true);
      this.$emit("update:value", newVal);
    },
  },
};
</script>

<style lang="scss">
.rating-list-content {
  .rating-list-overflow {
    .rating-header {
      display: flex;
      justify-content: space-between;
      max-width: 700px;
      margin-top: 25px;
      .end {
        text-align: right;
      }
    }
  }
}
</style>
